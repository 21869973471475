import { ORDER_CONFIGS } from '@configs/order'
import { SecurePaymentCode } from '@constants/paymentMethods'
import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import Log from '@services/Log'
import { CartPayload, ShippingChargesPayload } from '@typesApp/cart'
import { CheckoutPayload, CopyOrderPayload, KlarnaAuthTokenPay, PayerAuthResponse } from '@typesApp/checkout'
import { IPayloadPromoCode, IPayloadRemoveSubscription, IPayloadUpdateSubscription } from '@typesApp/common'
import {
  Cart,
  IOrderDetails,
  IOrderItem,
  IPromoCode,
  OrderItemWithRoxProps,
  PaymentInstruction,
  ShippingChargesWithoutPromotions,
  CreateReminderPayload,
  CreateReminderResponse,
} from '@typesApp/order'
import { ContactLensPayload } from '@typesApp/product'
import { getCookieByName } from '@utils/cookie'
import { fetchOrderItemsPrescriptionData, getOrderItemCatEntries, isClOrderItem } from '@utils/order'
import { isContactLensesProduct } from '@utils/product'
import { IOrderSliceState } from './IOrderSliceState'
import { WEB_ID_KEY } from '@foundation/forter/constants'
import {
  DeliveryDateUpdateResponse,
  DeliveryFrequencyUpdateResponse,
  SubscriptionData,
  SubscriptionDetails,
  SubscriptionStateToggleResponse,
} from '@typesApp/subscription'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    getCart: build.query<
      {
        cart: Cart
        catentries?: IOrderSliceState['catentries']
        shippingInfo: IOrderSliceState['shipInfos']
        checkInventory: boolean
        fetchShippingInfo: boolean
        filterPrescriptionItemType?: 'cl' | 'rx'
        filterPrescriptionNeededItems?: boolean
      },
      {
        storeId?: string
        body?: any
        checkInventory?: boolean
        filterPrescriptionItemType?: 'cl' | 'rx'
        filterPrescriptionNeededItems?: boolean
        fetchShippingInfo?: boolean
        fetchCatentries?: boolean
        fetchPrescriptionDetails?: boolean
        refetch?: boolean
        sessionId?: string | number
        currency?: string
        queryParams?: Record<string, string>
        updateProducts?: boolean
        catentries?: IOrderSliceState['catentries']
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self`,
            method: 'get',
            queryParams: args['queryParams'] || {},
            body: args.body || {},
            pathParams: {
              storeId: storeId,
            },
            extraParams: {
              skipServerErrorEvent: true,
            },
          })
          const {
            checkInventory,
            fetchShippingInfo,
            filterPrescriptionItemType,
            filterPrescriptionNeededItems,
            fetchPrescriptionDetails,
            updateProducts,
            catentries,
          } = args
          let newCatentries: IOrderSliceState['catentries']
          const shippingInfo = undefined
          let updatedOrderItems: IOrderItem[] | OrderItemWithRoxProps[] = []
          if (result) {
            const cart = result.data

            if (cart) {
              const orderItems = cart.orderItem
              if (orderItems && orderItems.length > 0) {
                const catentryIdList: string[] = []
                orderItems.forEach(async (item: IOrderItem) => {
                  catentryIdList.push(item.xitem_display_catentry_id)
                })
                //get product info for all items
                updatedOrderItems = fetchPrescriptionDetails
                  ? await fetchOrderItemsPrescriptionData(orderItems, storeId || '', cart.orderId)
                  : orderItems

                if (catentryIdList.length > 0 && updateProducts) {
                  newCatentries = await getOrderItemCatEntries(catentryIdList, storeId || '')
                }
              }
            }
          }

          if (updateProducts) {
            return {
              data: {
                cart: { ...result.data, orderItem: updatedOrderItems },
                catentries: newCatentries,
                checkInventory: checkInventory || false,
                shippingInfo,
                fetchShippingInfo: fetchShippingInfo || false,
                filterPrescriptionItemType,
                filterPrescriptionNeededItems: filterPrescriptionNeededItems || false,
                updateProducts,
              },
            }
          }
          return {
            data: {
              cart: { ...result.data, orderItem: updatedOrderItems },
              catentries,
              checkInventory: checkInventory || false,
              shippingInfo,
              fetchShippingInfo: fetchShippingInfo || false,
              filterPrescriptionItemType,
              filterPrescriptionNeededItems: filterPrescriptionNeededItems || false,
              updateProducts,
              //orderPrescriptionDetails,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    updateOrderItem: build.query<
      {
        orderItemId: string
      },
      CartPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/update_order_item`,
            method: 'put',
            body:
              {
                userAgent,
                forterToken,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                ...args.body,
              } || {},
            pathParams: { storeId },
          })
          return result?.data
            ? { data: { orderItemId: result.data.orderItem[0].orderItemId } }
            : { error: result?.error?.response.data.errors[0].errorCode }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    updateClOrderItem: build.query<
      {
        orderItemId: string
      },
      {
        storeId: string
        orderId: string
        items:
          | {
              x_contactLens: ContactLensPayload
              quantity: string
            }
          | {}[]
          | null
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId, orderId, items } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/update_order_item`,
            method: 'put',
            body:
              {
                userAgent,
                forterToken,
                orderId,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                orderItem: items,
              } || {},
            pathParams: { storeId },
          })
          return result?.data
            ? { data: { orderItemId: result.data.orderItem[0].orderItemId } }
            : { error: result?.error?.response.data.errors[0].errorCode }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    deleteOrderItem: build.query<
      {
        orderItemId: string
      },
      {
        storeId: string
        orderItem: IOrderItem
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId, orderItem } = args
          const { orderItemId, roxableServices } = orderItem
          const payload: Record<string, string> = {}
          const isClAccessoriesOrder = isContactLensesProduct(orderItem)

          if (roxableServices) {
            payload.orderItemId_1 = roxableServices[0]?.orderItemId
            payload.orderItemId_2 = orderItemId
            payload.orderItemId_3 = roxableServices[1]?.orderItemId
            payload.orderItemId_4 = roxableServices[2]?.orderItemId
            payload.orderItemId_5 = roxableServices[3]?.orderItemId
          } else if (isClAccessoriesOrder || isClOrderItem(orderItem.orderItemExtendAttribute)) {
            if (!!orderItem.groupedOrderItemsId) {
              orderItem.groupedOrderItemsId.map((orderId, index) => {
                const position = `orderItemId_${index.toString()}`
                payload[position] = orderId
              })
            } else {
              payload.orderItemId = orderItemId
            }
          } else {
            payload.orderItemId = orderItemId
          }

          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/delete_order_item`,
            method: 'put',
            body:
              {
                userAgent,
                forterToken,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                ...payload,
              } || {},
            pathParams: { storeId },
          })

          return {
            data: {
              orderItemId: result.data?.orderId[0],
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    copyOrder: build.mutation<
      {
        orderItemId: string
      },
      CopyOrderPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/copy_order`,
            method: 'POST',
            body:
              {
                userAgent,
                forterToken,
                ...args,
              } || {},
            pathParams: { storeId },
          })
          return {
            data: {
              orderItemId: result.data.orderItem[0].orderItemId,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    getShippingInfo: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId, orderId } = args
        const result = await fetchWithBQ({
          // url: `store/${storeId}/cart/@self/usable_shipping_info`,
          url: `store/${storeId}/ShipModes/GetShipModes?orderId=${orderId}`,
          method: 'get',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    getShippingInfoForUpc: build.query<{ storeId: string; upc: string }, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId, upc } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/ShipModes/GetShipModes?partNumber=${upc}`,
          method: 'get',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
          pathParams: { storeId },
        })
        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    updateOrderShippingInfo: build.mutation<any, any>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/shipping_info`,
          method: 'PUT',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    addPaymentInstruction: build.query<
      {
        orderId: string
        paymentInstruction: PaymentInstruction[]
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId, webId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/payment_instruction`,
            method: 'POST',
            body: {
              userAgent,
              forterToken,
              webId,
              ...args.body,
            },
            pathParams: { storeId },
          })
          return {
            data: {
              orderId: result.data.orderId,
              paymentInstruction: result.data.paymentInstruction,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    deleteAllPaymentInstructions: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/payment_instruction`,
          method: 'DELETE',
          body:
            {
              userAgent,
              forterToken,
              webId: args?.webId,
              ...args.body,
            } || {},
          pathParams: { storeId },
        })
        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    updatePaymentInstruction: build.query<
      {
        orderId: string
        paymentInstruction: PaymentInstruction[]
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const webId = localStorage.getItem(WEB_ID_KEY)
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/payment_instruction`,
            method: 'PUT',
            body:
              {
                userAgent,
                forterToken,
                webId,
                ...args.body,
              } || {},
            pathParams: { storeId },
          })
          return {
            data: {
              orderId: result.data.orderId,
              paymentInstruction: result.data.paymentInstruction,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),

    finalizePreCheckoutWithCybersource: build.mutation<
      {
        orderId: string
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, body } = args

        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/precheckout`,
          method: 'PUT',
          body: body || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: { orderId: body?.orderId || '' } } : { error: result.error }
      },
    }),

    finalizeCheckoutWithCybersource: build.mutation<
      {
        orderComplete: boolean
        orderId: string
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId, body } = args

        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/checkout`,
          method: 'POST',
          body: {
            userAgent,
            forterToken,
            ...body,
          },
          pathParams: { storeId },
        })

        return result?.data ? { data: { orderComplete: true, orderId: body?.orderId || '' } } : { error: result.error }
      },
    }),

    finalizeOrderWithCybersource: build.query<
      {
        orderComplete: boolean
        orderId: string
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId, body } = args
        await fetchWithBQ({
          url: `store/${storeId}/cart/@self/precheckout`,
          method: 'PUT',
          body: body || {},
          pathParams: { storeId },
        })
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/checkout`,
          method: 'POST',
          body: {
            userAgent,
            forterToken,
            ...body,
          },
          pathParams: { storeId },
        })

        return result?.data ? { data: { orderComplete: true, orderId: body?.orderId || '' } } : { error: result.error }
      },
    }),
    findOrderdById: build.query<
      {
        orderData: IOrderDetails
      },
      {
        storeId: string
        orderId: string
        shouldFetchCatentries?: boolean
      } & Partial<CheckoutPayload>
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, shouldFetchCatentries } = args
        //get product info for all items
        const result = await fetchWithBQ({
          url: `store/${storeId}/order/${orderId}`,
          method: 'GET',
          body:
            {
              ...args.body,
              profileName: 'LX_findItemByIds_Details',
            } || {},
          pathParams: { storeId },
        })

        if (result) {
          const updatedOrderItems = await fetchOrderItemsPrescriptionData(
            result.data?.orderItem as IOrderItem[],
            storeId || '',
            result.data.orderId
          )
          if (shouldFetchCatentries) {
            let catentries: IOrderSliceState['catentries']

            const catentryIdList: string[] = []
            updatedOrderItems.forEach((item: IOrderItem) => {
              catentryIdList.push(item.xitem_display_catentry_id)
            })

            if (catentryIdList.length > 0) {
              catentries = await getOrderItemCatEntries(catentryIdList, storeId || '')
            }
            if (!!catentries) {
              return {
                data: {
                  orderData: { ...result.data, orderItem: updatedOrderItems },
                  catentries,
                },
              }
            }
          }
          return {
            data: {
              orderData: { ...result.data, orderItem: updatedOrderItems },
            },
          }
        }

        return { error: result.error }
      },
    }),
    setupPayerAuthentication: build.query<PayerAuthResponse, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/payerAuthentication/paSetup/@self`,
          method: 'GET',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    checkPayerAuthenticationEnroll: build.query<
      { orderId: string; reasonCode: SecurePaymentCode },
      CheckoutPayload & { redirectUrl: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const getTimeDifference = () => {
          const now = new Date()
          const utcTime = now.getTime() + now.getTimezoneOffset() * 60000
          const localTime = now.getTime()
          return (localTime - utcTime) / 60000
        }
        const result = await fetchWithBQ({
          url: `store/${storeId}/payerAuthentication/paCheckEnroll/@self`,
          method: 'POST',
          body: {
            userAgent,
            forterToken,
            billTo_httpBrowserJavaEnabled: false,
            billTo_httpBrowserLanguage: window.navigator.language,
            billTo_httpBrowserColorDepth: window.screen.colorDepth,
            billTo_httpBrowserScreenHeight: window.innerHeight,
            billTo_httpBrowserScreenWidth: window.innerWidth,
            billTo_httpBrowserTimeDifference: getTimeDifference(),
            payerAuthEnrollService_httpUserAgent: window.navigator.userAgent,
            payerAuthEnrollService_deviceChannel: 'Browser',
            billTo_httpBrowserJavaScriptEnabled: true,
            payerAuthEnrollService_httpUserAccept: 'application/json',
            // payerAuthEnrollService_returnURL
            returnUrl: args.redirectUrl,
            ...args.body,
          },
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    validatePayerAuthentication: build.query<PayerAuthResponse, CheckoutPayload>({
      // TODO: find the proper way to use this, or delete it
      // It currently invalidates the order (complains PI is 0 after the call) if called before finalize
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/payerAuthentication/paValidate/@self`,
          method: 'GET',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    paypalCheckPaymentStatus: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/paypal/checkStatus/@self`,
          method: 'GET',
          body: {
            userAgent,
            forterToken,
            ...args.body,
          },
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),

    getShippingPrice: build.query<{ shipList: ShippingChargesWithoutPromotions[] }, ShippingChargesPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId } = args
        try {
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/orderId/${orderId}/shipCharges`,
            method: 'GET',
            pathParams: { storeId },
          })
          return result?.data && result.data.ships.shipList.length
            ? { data: result.data.ships }
            : { error: result.error }
        } catch (e) {
          Log.error('Could not retrieve shipping price')
          return {
            error: e,
          }
        }
      },
    }),
    applyPromotioncode: build.query<IPromoCode, { storeId: string; payload: IPayloadPromoCode }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/assigned_promotion_code`,
          method: 'POST',
          body: {
            ...payload,
          },
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
    removePromotioncode: build.query<IPromoCode, { storeId: string; promoCode: string; payload: IPayloadPromoCode }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, promoCode, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/assigned_promotion_code/${promoCode}`,
          method: 'DELETE',
          body: {
            ...payload,
          },
          pathParams: { storeId, promoCode },
          extraParams: {
            skipErrorSnackbar: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
    updateOrderSubscription: build.query<IPromoCode, { storeId: string; payload: IPayloadUpdateSubscription }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/update_recurring_frequency`,
          method: 'PUT',
          body: {
            ...payload,
          },
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
    removeOrderSubscription: build.query<IPromoCode, { storeId: string; payload: IPayloadRemoveSubscription }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, payload } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/delete_recurring_frequency`,
          method: 'DELETE',
          body: {
            ...payload,
          },
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
    createKlarnaDirectSession: build.query<any, { storeId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId } = args
        const userAgent = window.navigator.userAgent
        const forterToken = getCookieByName('forterToken')

        const result = await fetchWithBQ({
          url: `/store/${storeId}/klarnadirect/create-session`,
          method: 'GET',
          body: {
            userAgent,
            forterToken,
          },
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
          },
        })
        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
    updateKlarnaAuthToken: build.query<any, { authTokenPayload: KlarnaAuthTokenPay; storeId: string; langId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, authTokenPayload, langId } = args
        const userAgent = window.navigator.userAgent
        const forterToken = getCookieByName('forterToken')

        const result = await fetchWithBQ({
          url: `/store/${storeId}/klarnadirect/update-authToken`,
          method: 'POST',
          body: {
            userAgent,
            forterToken,
            ...authTokenPayload,
          },
          pathParams: { storeId, langId },
          extraParams: {
            skipErrorSnackbar: true,
          },
        })

        return result.data ? { data: result.data } : { error: result.error }
      },
    }),
    getSubscriptionHistory: build.query<SubscriptionData, { storeId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/@history`,
          method: 'GET',
          pathParams: { storeId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })
        return result.data ? { data: result.data as SubscriptionData } : { error: result.error }
      },
    }),
    getSubscriptionDetails: build.query<SubscriptionDetails, { storeId: string; subscriptionId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}`,
          method: 'GET',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipErrorSnackbar: true,
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data as SubscriptionDetails } : { error: result.error }
      },
    }),
    cancelSubscription: build.query<SubscriptionStateToggleResponse, { storeId: string; subscriptionId: string }>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}`,
          method: 'DELETE',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data as SubscriptionStateToggleResponse } : { error: result.error }
      },
    }),
    pauseSubscription: build.query<
      SubscriptionStateToggleResponse,
      { storeId: string; subscriptionId: string; parentOrderId: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, parentOrderId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${parentOrderId}/cancel_recurring_or_subscription?subscriptionId=${subscriptionId}`,
          method: 'DELETE',
          pathParams: { storeId, parentOrderId },
          extraParams: {
            skipServerErrorEvent: true,
          },
        })

        return result.data ? { data: result.data as SubscriptionStateToggleResponse } : { error: result.error }
      },
    }),
    reactivateSubscription: build.query<
      SubscriptionStateToggleResponse,
      { storeId: string; subscriptionId: string; parentOrderId: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, parentOrderId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${parentOrderId}/activate_recurring_or_subscription`,
          method: 'POST',
          pathParams: { storeId, parentOrderId },
          extraParams: {
            skipServerErrorEvent: true,
          },
          body: {
            subscriptionId,
          },
        })

        return result.data ? { data: result.data as SubscriptionStateToggleResponse } : { error: result.error }
      },
    }),
    updateNextDeliveryDate: build.query<
      DeliveryDateUpdateResponse,
      { storeId: string; subscriptionId: string; nextFulfillmentDate: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, nextFulfillmentDate } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}/nextfulfillmentdate`,
          method: 'PUT',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipServerErrorEvent: true,
          },
          body: {
            nextFulfillmentDate,
          },
        })

        return result.data ? { data: result.data as DeliveryDateUpdateResponse } : { error: result.error }
      },
    }),
    updateDeliveryFrequency: build.query<
      DeliveryFrequencyUpdateResponse,
      { storeId: string; subscriptionId: string; fulfillmentInterval: string; fulfillmentIntervalUOM: string }
    >({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { storeId, subscriptionId, fulfillmentInterval, fulfillmentIntervalUOM } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/subscription/${subscriptionId}/period`,
          method: 'PUT',
          pathParams: { storeId, subscriptionId },
          extraParams: {
            skipServerErrorEvent: true,
          },
          body: {
            fulfillmentInterval,
            fulfillmentIntervalUOM,
          },
        })

        return result.data ? { data: result.data as DeliveryFrequencyUpdateResponse } : { error: result.error }
      },
    }),
    /**
     * @deprecated Use new version instead: src\features\orderReminder\query.ts,createOrderReminder
     */
    createReminderDate: build.query<CreateReminderResponse, CreateReminderPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        // TODO these should be addressed with preferences
        const { storeId, orderId, enabled = 'Y', sms = 'Y', email = 'Y' } = args
        let data = { enabled, sms, email }
        const result = await fetchWithBQ({
          method: 'POST',
          body: data,
          pathParams: { storeId },
          url: `store/${storeId}/reorderreminder/${orderId}`,
        })
        return result?.data && result.data.orderId ? { data: result.data } : { error: result.error }
      },
    }),
  }),
})

export const {
  useGetCartQuery,
  useFindOrderdByIdQuery,
  useLazyGetCartQuery,
  useLazyFindOrderdByIdQuery,
  useLazyGetSubscriptionHistoryQuery,
  useGetSubscriptionHistoryQuery,
  useGetSubscriptionDetailsQuery,
} = orderApi
