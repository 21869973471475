import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPaypal = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 40 26" {...other}>
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V22C39.5 23.933 37.933 25.5 36 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V22C39.5 23.933 37.933 25.5 36 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4Z"
        stroke="#CCCFD9"
        fill="white"
      />
      <path
        d="M17.1721 21.3737L17.4814 19.4092L16.7925 19.3933H13.5028L15.789 4.89745C15.7961 4.85369 15.8191 4.81289 15.8528 4.78391C15.8865 4.75493 15.9297 4.73897 15.9746 4.73897H21.5215C23.363 4.73897 24.6338 5.12216 25.2973 5.8785C25.6084 6.23332 25.8065 6.60409 25.9023 7.01213C26.0028 7.44026 26.0046 7.95178 25.9064 8.57566L25.8993 8.6212V9.02095L26.2104 9.19717C26.4723 9.33614 26.6805 9.49521 26.8401 9.67735C27.1063 9.98071 27.2783 10.3663 27.3511 10.8234C27.4262 11.2935 27.4013 11.8529 27.2783 12.4863C27.1364 13.2148 26.907 13.8493 26.5971 14.3685C26.3121 14.847 25.949 15.2437 25.5179 15.5513C25.1063 15.8434 24.6173 16.0651 24.0643 16.2071C23.5286 16.3466 22.9177 16.417 22.2477 16.417H21.816C21.5073 16.417 21.2075 16.5282 20.9722 16.7275C20.7362 16.9309 20.5801 17.2088 20.5322 17.5128L20.4997 17.6896L19.9533 21.1519L19.9284 21.2791C19.9219 21.3193 19.9107 21.3394 19.8941 21.353C19.8793 21.3654 19.8581 21.3737 19.8374 21.3737H17.1721Z"
        fill="#253B80"
      />
      <path
        d="M26.5048 8.66732C26.4883 8.77317 26.4694 8.88139 26.4481 8.99256C25.7166 12.7482 23.214 14.0457 20.0177 14.0457H18.3903C17.9994 14.0457 17.67 14.3295 17.6091 14.7151L16.7759 19.9994L16.54 21.4973C16.5004 21.7504 16.6955 21.9787 16.951 21.9787H19.8374C20.1792 21.9787 20.4695 21.7303 20.5233 21.3932L20.5517 21.2466L21.0952 17.7978L21.13 17.6086C21.1833 17.2703 21.4742 17.0219 21.816 17.0219H22.2477C25.0442 17.0219 27.2334 15.8865 27.8732 12.601C28.1405 11.2285 28.0022 10.0824 27.2949 9.27641C27.0808 9.03337 26.8153 8.83172 26.5048 8.66732Z"
        fill="#179BD7"
      />
      <path
        d="M25.7396 8.36218C25.6279 8.32966 25.5126 8.30009 25.3943 8.27348C25.2754 8.24746 25.1536 8.2244 25.0282 8.20429C24.5895 8.13333 24.1087 8.09962 23.5936 8.09962H19.246C19.139 8.09962 19.0373 8.12387 18.9462 8.16763C18.7457 8.26402 18.5967 8.45384 18.5606 8.68624L17.6357 14.5442L17.6091 14.7151C17.67 14.3295 17.9994 14.0457 18.3903 14.0457H20.0177C23.214 14.0457 25.7166 12.7482 26.4481 8.99256C26.4694 8.88139 26.4883 8.77317 26.5048 8.66732C26.3198 8.56916 26.1193 8.48518 25.9034 8.41363C25.8502 8.39589 25.7952 8.37874 25.7396 8.36218Z"
        fill="#222D65"
      />
      <path
        d="M18.5606 8.68624C18.5967 8.45384 18.7457 8.26402 18.9462 8.16763C19.0373 8.12387 19.139 8.09962 19.246 8.09962H23.5936C24.1087 8.09962 24.5895 8.13333 25.0282 8.20429C25.1536 8.2244 25.2754 8.24746 25.3943 8.27348C25.5126 8.30009 25.6279 8.32966 25.7396 8.36218C25.7952 8.37874 25.8502 8.39589 25.9034 8.41363C26.1193 8.48518 26.3198 8.56916 26.5048 8.66732C26.7225 7.27942 26.5037 6.33444 25.7532 5.47875C24.9259 4.53673 23.4328 4.13342 21.5221 4.13342H15.9752C15.5849 4.13342 15.252 4.41727 15.1917 4.80342L12.8813 19.4483C12.8357 19.738 13.0593 19.9994 13.3514 19.9994H16.7759L17.6357 14.5442L18.5606 8.68624Z"
        fill="#253B80"
      />
    </SvgIcon>
  )
}
export default SvgPaypal
