import { XDataSubscriptions } from '@typesApp/user'
import {
  ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS,
  SUBSCRIPTION_STATES,
  SubscriptionResult,
  SubscriptionProduct,
  SubscriptionProductAccmulated,
} from '@typesApp/subscription'
import { ParsedUrlQuery } from 'querystring'
import { setActiveSubscriptionsCountUpdate } from '@features/user/slice'
import { AppDispatch } from '@redux/store'

export const getActiveSubscriptionsQty = (subscriptions: XDataSubscriptions[]): number => {
  if (!subscriptions || subscriptions?.length === 0) {
    return 0
  }

  return subscriptions?.filter(
    subscription => subscription?.subscriptionStatus === ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS.ACTIVE
  ).length
}

export const getActiveSubscriptionsQuantityByStateName = (subscriptions: SubscriptionResult[]): number => {
  if (!subscriptions || subscriptions?.length === 0) {
    return 0
  }

  return subscriptions.filter(subscription => subscription.state === SUBSCRIPTION_STATES.ACTIVE).length
}

export const getSubscriptionId = (routerQuery: ParsedUrlQuery): string => {
  const { pageName: pageRouterParam } = routerQuery as { pageName: string[] }
  const subscriptionId = Array.isArray(pageRouterParam) ? pageRouterParam[pageRouterParam.length - 1] : ''

  return subscriptionId
}

//any type for getSubscriptionHistory function to be passed as a parameter
export const updateActiveSubscriptionsQuantity = async (
  dispatch: AppDispatch,
  getSubscriptionHistory: any,
  storeId: string
): Promise<void> => {
  const { data, error } = await getSubscriptionHistory({ storeId })

  if (!error) {
    const activeSubscriptionsQuantity = getActiveSubscriptionsQuantityByStateName(data?.resultList || [])
    dispatch(setActiveSubscriptionsCountUpdate(activeSubscriptionsQuantity))
  }
}

export const getParsedAccumulatedProducts = (products: SubscriptionProduct[]): SubscriptionProductAccmulated[] => {
  const accumulatedProducts: SubscriptionProductAccmulated[] = []
  const parsedProducts = products.reduce((acc, product) => {
    const { x_contactLens, itemQty, ...rest } = product
    if (x_contactLens && x_contactLens.x_productId) {
      const { x_eye, x_productId } = x_contactLens
      if (!acc[x_productId]) {
        acc[x_productId] = { L_eye: [], R_eye: [] }
      }

      acc[x_productId] = { ...rest, ...acc[x_productId] }
      acc[x_productId].itemQty = parseInt(acc[x_productId].itemQty || '0') + parseInt(itemQty)
      acc[x_productId].isContactLens = true
      acc[x_productId].packSize = parseInt(x_contactLens.cl_pack_size || '1')
      acc[x_productId].itemTotalPrice = parseFloat(acc[x_productId].itemPrice) * acc[x_productId].itemQty
      acc[x_productId].itemDiscountedTotalPrice = acc[x_productId].discountedPrice
        ? parseFloat(acc[x_productId].discountedPrice) * acc[x_productId].itemQty
        : null
      if (x_eye === 'LCON') {
        acc[x_productId].L_eye.push(x_contactLens)
      } else if (x_eye === 'RCON') {
        acc[x_productId].R_eye.push(x_contactLens)
      }
    } else {
      acc[product.productName] = { ...rest, itemQty: parseInt(itemQty), isContactLens: false, packSize: 1 }
      acc[product.productName].itemTotalPrice =
        parseFloat(acc[product.productName].itemPrice) * acc[product.productName].itemQty
      acc[product.productName].itemDiscountedTotalPrice = acc[product.productName].discountedPrice
        ? parseFloat(acc[product.productName].discountedPrice) * acc[product.productName].itemQty
        : null
    }

    return acc
  }, {})
  for (const key in parsedProducts) {
    accumulatedProducts.push(parsedProducts[key])
  }
  return accumulatedProducts
}

export const getSubscriptionOrderTotal = (products: SubscriptionProductAccmulated[]): number => {
  const orderTotal = products.reduce((acc, product) => {
    let discountedPriceOrDefault = product.itemTotalPrice
    if (Math.abs(product.itemAdjustment) > 0 && product.itemDiscountedTotalPrice) {
      discountedPriceOrDefault = product.itemDiscountedTotalPrice
    }
    return acc + discountedPriceOrDefault
  }, 0)
  return orderTotal
}

export const getProductUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return '/' + url.split('/').slice(3).join('/')
  }
  return url
}

export const escapeProductString = (str: string): string => {
  return str.replaceAll('\\\\', '\\')
}
