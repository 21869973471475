import config from '@configs/index'
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils'
import cmsRewritesMapping from '@configuration/domains/cms-rewrites-mapping.json'
import { IncomingMessage } from 'node:http'
import { getHostnameUrls } from './getHostnameUrls'
import { DomainLocale, I18NConfig } from 'next/dist/server/config-shared'
import { CommerceEnvironment } from '@constants/common'
import { getLanguageCodeFromLocale } from './countryUtil'
import { HOME } from '@constants/routes'

/**
 * Removes port number from hostname
 * @param hostname
 * @returns hostname without :PORT
 */
export const stripPortFromHost = (hostname: string) => hostname?.split(':')?.[0]

/**
 * Takes a locale string or uses the current locale from the default config if not supplied
 * @param locale Optional locale string
 * @returns string
 */
export const localeOrDefault = (locale?: string) => locale?.replace('_', '-') || config.defaultLocale

type ReplaceLocaleInUrl = {
  isLocaleDomain: boolean
  locationOrigin: string
  locale: string | undefined
  href: string | undefined
  isRelative?: boolean
}

const beServerLocales = {
  nl: 'nl-be',
  fr: 'fr-be',
} as const

const beClientLocales = {
  'nl-be': 'nl',
  'fr-be': 'fr',
} as const

export const getServerLocale = (isLocaleDomain: boolean, locale: string | undefined) => {
  if (isLocaleDomain) {
    return locale?.length == 2 ? beServerLocales[locale] ?? locale : locale
  }

  return locale
}

export const getClientLocale = (isLocaleDomain: boolean, locale: string) => {
  if (isLocaleDomain) {
    return locale?.length == 5 ? beClientLocales[locale] ?? locale : locale
  }

  return locale
}

export const replaceLocaleInUrl = ({
  isLocaleDomain,
  locationOrigin,
  locale,
  href,
  isRelative,
}: ReplaceLocaleInUrl): string | undefined => {
  const lang = getLanguageCodeFromLocale(locale)
  let internalHref: string
  const hrefLangMap = CommerceEnvironment.hrefLangMap
  const hrefLangLocaleList = Object.keys(hrefLangMap)

  if (!href || !isLocaleDomain || isAbsoluteUrl(href)) {
    return href ? remapCMSURL(href) : ''
  }

  if (locationOrigin.includes('.be')) {
    const internalLocale = getServerLocale(isLocaleDomain, locale as string)
    const startsWithLocale = href.startsWith(`/${internalLocale}`)
    internalHref = startsWithLocale ? href.replace(`/${internalLocale}`, `/${lang}`) : `/${lang}${href}`
    internalHref = remapCMSURL(internalHref)
  } else if (locale === hrefLangMap['x-default']) {
    const localePrefix = hrefLangLocaleList.find(locale => href.startsWith(`/${locale}`))
    internalHref = localePrefix ? href.replace(`/${localePrefix}`, '') : href
    internalHref = remapCMSURL(internalHref)
  } else {
    const startsWithLocale = href.startsWith(`/${locale}`)
    internalHref = startsWithLocale ? href.replace(`/${locale}`, '') : href
    internalHref = remapCMSURL(internalHref)
  }
  const finalHref = isRelative ? internalHref : new URL(internalHref, locationOrigin).href
  return finalHref
}

const remapCMSURL = (href: string) => {
  let modifiedHref = href.replace(/\/en-gb|en-gb|en-uk|\/en-uk/g, '')

  if (
    modifiedHref.includes('/c/') &&
    cmsRewritesMapping.some(
      x => modifiedHref.includes(`/c${x}/`) || modifiedHref.endsWith(`${x}`) || modifiedHref.endsWith(`${x}/`)
    )
  ) {
    return modifiedHref.replace('/c/', '/')
  }

  return modifiedHref.length ? modifiedHref : HOME
}

export const parseAndReplaceLocaleInCMSResponse = (
  isLocaleDomain: boolean,
  locale: string | undefined,
  detailText: string,
  locationOrigin: string
): string => {
  const urlRegex = /href="(\/[a-z]{2}-[a-z]{2}\/[^"]*)"/gi
  return detailText.replace(urlRegex, (_match, link) => {
    const href = link
    const newUrl = replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href })
    return `href="${newUrl}"`
  })
}

export type LocalesDomainsConfig = {
  domainLocales: DomainLocale | null
  locationOrigin: string
  isLocaleDomain: boolean
}

export const getLocalesDomainsConfig = (
  request: IncomingMessage,
  domains: I18NConfig['domains']
): LocalesDomainsConfig => {
  const { hostname, locationOrigin } = getHostnameUrls(request)
  const hostnameWithoutPort = process?.env?.NODE_ENV === 'development' ? stripPortFromHost(hostname) : hostname //  Required for local development otherwise configs don't resolve
  const domainLocale = domains?.filter(c => c.domain === hostnameWithoutPort)?.[0]
  const isLocaleDomain = Boolean(domainLocale)

  return {
    domainLocales: domainLocale ?? null,
    isLocaleDomain,
    locationOrigin,
  }
}

/**
 * Maps a langId to a locale e.g. -45 to en-ie
 * @param langId
 * @returns formatted locale
 */
export const resolveLangIdToLocale = (langId: string): string => {
  const langCode = CommerceEnvironment?.languageMap[langId]
  const locale = langCode ? langCode.toLowerCase().replace('_', '-') : ''

  return locale
}
