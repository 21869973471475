import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import {
  ICmsPreviewArgs,
  ICommerceHclPage,
  IFooter,
  IHeader,
  IMegaMenu,
  IPage,
  IPageStaticLanding,
  IQueryParams,
  ISitemapItem,
} from '@typesApp/cms'
import qs from 'qs'
import config from '../../configs'
import { RootReducerState } from '../../redux/reducers'
import { STORES } from '@foundation/constants/site'
import { helper } from '@foundation/apis/helpers'

const storeName = config.cms_store_name?.toLowerCase() as string
const getLang = (locale: string) => locale.split('_')[0]
//TODO: use for test algolia
//country.toLowerCase() !== 'ca' ? country : 'gb'
const tempParams = {
  /*  storeId: '60151',
  langId: '-43',*/
}
export const cmsApi = createApi({
  reducerPath: 'cmsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.cmsApiUrl,

    paramsSerializer: params => {
      if (!params) {
        return ''
      }
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    credentials: 'include',
  }),
  endpoints: builder => ({
    getHeader: builder.query<IHeader, IQueryParams | void>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID } = state.site.currentSite!
        const lowerCaseLocale = locale.toLowerCase().replace('_', '-')

        const params = {
          storeId: storeID,
          langId: STORES[lowerCaseLocale].langId,
          includePlacements: args?.includePlacements,
        }

        const result = await baseQuery({
          url: `cms/live/page/header/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as IHeader)
          ? { data: result.data as IHeader }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getMegaMenu: builder.query<IMegaMenu, IQueryParams | void>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, defaultLanguageID } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: defaultLanguageID,
          profile: args['profile'],
        }

        const result = await baseQuery({
          url: `cms/live/page/mega-menu/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as IMegaMenu)
          ? { data: result.data as IMegaMenu }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPage: builder.query<IPage, IQueryParams | void>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
          previewDate: args?.previewDate,
          includePlacements: args?.includePlacements,
          noEnrich: args?.noEnrich,
        }

        const result = await baseQuery({
          url: `cms/live/home/content/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as IPage) ? { data: result.data as IPage } : { error: result.error as FetchBaseQueryError }
      },
    }),
    getFooter: builder.query<IFooter, IQueryParams | void>({
      queryFn: async (_args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, defaultLanguageID } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: defaultLanguageID,
        }

        const result = await baseQuery({
          url: `cms/live/page/footer/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as IFooter)
          ? { data: result.data as IFooter }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getHclPage: builder.query<ICommerceHclPage, { externalId: string; noEnrich: string }>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          externalId: args.externalId,
          noEnrich: args.noEnrich,
        }

        const result = await baseQuery({
          url: `cms/live/hcl/content/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as ICommerceHclPage)
          ? { data: result.data as ICommerceHclPage }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPlpCommerce: builder.query<ICommerceHclPage, IQueryParams>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          ...args,
          noEnrich: args?.noEnrich,
        }

        const result = await baseQuery({
          url: `cms/live/plp/content/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as ICommerceHclPage)
          ? { data: result.data as ICommerceHclPage }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPdpCommerce: builder.query<ICommerceHclPage, IQueryParams>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          ...args,
          noEnrich: args?.noEnrich,
        }

        const result = await baseQuery({
          url: `cms/live/pdp/content/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as ICommerceHclPage)
          ? { data: result.data as ICommerceHclPage }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPageStaticLandingRX: builder.query<IPageStaticLanding, string>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!
        const { isEnabled, previewDate, filterRulesLocaleOverride } = state.preview
        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          filterRulesLocaleOverride: isEnabled ? filterRulesLocaleOverride : undefined,
          previewDate: isEnabled ? previewDate : undefined,
          pageId: args,
        }
        const result = await baseQuery({
          url: `cms/live/page/content/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params,
        })
        return (result.data as IPageStaticLanding)
          ? { data: result.data as IPageStaticLanding }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPageStaticLanding: builder.query<IPageStaticLanding, IQueryParams>({
      queryFn: async (args, api, _extraOptions, baseQuery) => {
        const state = api.getState() as RootReducerState
        const { locale, country, storeID, langId } = state.site.currentSite!

        const params: IQueryParams = {
          storeId: storeID,
          langId: langId,
          ...args,
          noEnrich: args?.noEnrich,
        }

        const result = await baseQuery({
          url: `cms/live/page/content/${storeName}/${getLang(locale)}/${helper.getCountry(country)}`,
          params: { ...params, ...tempParams },
        })

        return (result.data as IPageStaticLanding)
          ? { data: result.data as IPageStaticLanding }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPreviewContent: builder.query<any, ICmsPreviewArgs>({
      query: args => ({
        url: `cms/preview/component/${args.id}`,
        params: args.params,
      }),
      transformResponse: (response: any) => response.content.content,
    }),
    getPreviewPage: builder.query<any, ICmsPreviewArgs>({
      query: args => ({
        url: `cms/preview/page/${args.id}`,
        params: args.params,
      }),
      transformResponse: (response: any, _meta, args) => {
        const { filterRulesLocaleOverride, previewDate } = args.params
        return {
          ...response,
          isEnabled: !!response,
          filterRulesLocaleOverride,
          previewDate,
        }
      },
    }),
    getSitemap: builder.query<ISitemapItem[], void>({
      query: () => ({
        url: `sitemap/${storeName}/en/gb`,
        params: { baseUrl: 'https://www.davidclulow.com', json: true },
      }),
    }),
  }),
})

export const {
  useGetHeaderQuery,
  useGetMegaMenuQuery,
  useGetPageQuery,
  useGetPageStaticLandingQuery,
  useGetPageStaticLandingRXQuery,
  useGetFooterQuery,
  useGetHclPageQuery,
  useGetPlpCommerceQuery,
  useGetPdpCommerceQuery,
  useGetPreviewContentQuery,
  useGetPreviewPageQuery,
  useGetSitemapQuery,
} = cmsApi
