import { Wallet, GeneralUserFields } from './user'
import { GeneralFormFields } from './form'
import { CheckoutName, Icon } from '@constants/paymentMethods'
import { PAYMENTS_LIST_DESCRIPTION } from '@constants/paymentMethods'

export interface SubscriptionData {
  recordSetComplete: boolean
  recordSetCount: number
  recordSetTotal: number
  resourceId: string
  resourceName: string
  resultList: SubscriptionResult[]
}

export interface SubscriptionResult {
  subscriptionInfo: SubscriptionInfo
  state: SUBSCRIPTION_STATES
  purchaseDetails: PurchaseDetails
  subscriptionIdentifier: SubscriptionIdentifier
}

export interface SubscriptionInfo {
  userData: UserData
  fulfillmentSchedule: FulfillmentSchedule
  paymentSchedule: PaymentSchedule
  paymentInfo: PaymentInfo
}

export interface UserData {
  userDataField: UserDataField[]
}

export interface UserDataField {
  value: string
  key: string
}

export interface FulfillmentSchedule {
  startInfo: StartInfo
  userData: null | Record<string, unknown>
  frequencyInfo: FrequencyInfo
  endInfo: EndInfo
}

export interface StartInfo {
  userData: null | Record<string, unknown>
  startDate: string
}

export interface FrequencyInfo {
  nextOccurence: string
  userData: null | Record<string, unknown>
  remainingOccurrences: null | number
  completedOccurrences: null | number
  frequency: Frequency
}

export interface Frequency {
  uom: string
  value: number
}

export interface EndInfo {
  duration: null | number
  totalOccurrences: null | number
  userData: null | Record<string, unknown>
  endDate: string
}

export interface PaymentSchedule {
  startInfo: null | Record<string, unknown>
  userData: UserData
  frequencyInfo: FrequencyInfo
  endInfo: null | Record<string, unknown>
}

export interface PaymentInfo {
  userData: UserData
  amountPaid: null | number
  amountToCharge: null | number
  totalCost: TotalCost
}

export interface TotalCost {
  currency: string
  value: number
}

export interface PurchaseDetails {
  userData: UserData
  parentOrderIdentifier: ParentOrderIdentifier
}

export interface ParentOrderIdentifier {
  parentOrderId: string
}

export interface SubscriptionIdentifier {
  subscriptionId: string
}

export interface ProductData {
  productImgUrl: string
  productName: string
  productUrl: string
  x_contactLens: Record<string, unknown>
}

export interface SubscriptionCardData {
  subscriptionId: string
  status: SubscriptionStatus
  frequency: string
  estimatedDeliveryDate: string | null
  manageSubscriptionLabel: string
  productImageUrl: string
  orderItemsCount: number
}

export interface SubscriptionStatus {
  status: SUBSCRIPTION_STATES | null
  statusLabelText: SUBSCRIPTION_STATES_LABELS | null
  statusLabelColor: string
  isEditable: boolean
  badgeColor: string
  message: string
  Icon: any
  color: string
}

export enum SUBSCRIPTION_STATES {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
  CANCELLED = 'Cancelled',
}

export enum SUBSCRIPTION_STATES_LABELS {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  CANCELLED = 'Cancelled',
}

export enum SUBSCRIPTION_FREQUENCY_UNITS {
  WEEK = 'WEEK',
  MONTH = 'MON',
}

export enum ACCOUNT_SUBSCRIPTION_STATUS_NUMBERS {
  INACTIVE = '0',
  ACTIVE = '1',
  CANCELLED = '3',
}

export interface SubscriptionStateToggleResponse {
  subscriptionType: [string]
  resourceName: string
  state: [SUBSCRIPTION_STATES]
  subscriptionId: [string]
}

export type SubscriptionWallet = Pick<Wallet, 'issuer' | 'payMethodId' | 'protectedCCNumber' | 'expireDate'> & {
  walletId: string
  payMethodName: CheckoutName
}

export type Address = Required<
  Pick<GeneralFormFields, 'addressId' | 'city' | 'country' | 'addressType' | 'state' | 'zipCode' | 'addressLine'>
> &
  Pick<GeneralUserFields, 'firstName' | 'lastName' | 'phone1' | 'personTitle'> & {
    buzzerCode: string
  }

export interface SubscriptionDetails extends SubscriptionData {
  x_data: SubscriptionDetailXData
}

export type subscriptionPaymentData = SubscriptionWallet | SubscriptionPaymentInfo | null

export type SubscriptionPayMethodId = (typeof PAYMENTS_LIST_DESCRIPTION)[keyof typeof PAYMENTS_LIST_DESCRIPTION]

export interface SubscriptionPaymentInfo {
  payMethodId: SubscriptionPayMethodId
  payMethodName: CheckoutName
}

export interface SubscriptionDetailXData {
  wallet?: SubscriptionWallet
  prescriptionId: string
  statusReason: string
  nextDeliveryDate: string
  products: string
  addresses: Address[]
  PaymentInfo?: SubscriptionPaymentInfo
}

export interface DeliveryDateUpdateResponse {
  nextFulfillmentDate: string
  resourceName: string
  subscriptionId: number
}

export interface DeliveryFrequencyUpdateResponse {
  orderId: number
  resourceName: string
}

export interface SubscriptionPaymentMethodBox {
  Icon: Icon | null
  cardLastFourDigitsLabel: string
  isExpired: boolean
  expiredLabel: string
  iconFullWidth?: boolean
}

export interface SubscriptionProduct {
  itemQty: string
  productImgUrl: string
  x_contactLens?: XContactLens
  subscriptionExpectedPercDiscount: string
  discountedPrice: number
  itemAdjustment: number
  itemPrice: string
  productUrl: string
  productName: string
}

export interface SubscriptionProductAccmulated {
  itemQty: number
  productImgUrl: string
  L_eye?: XContactLens
  R_eye?: XContactLens
  subscriptionExpectedPercDiscount: string
  discountedPrice: number
  itemAdjustment: number
  itemPrice: string
  productUrl: string
  productName: string
  isContactLens: boolean
  packSize: number | null
  itemTotalPrice: number
  itemDiscountedTotalPrice: number | null
}

export interface XContactLens {
  x_productId?: string
  cl_pack_size?: string
  x_baseCurve?: string
  x_cylinder?: any
  x_color?: any
  x_spherePower?: string
  x_axis?: any
  x_addition?: any
  x_partNumber?: string
  x_eye?: string
  x_dominance?: string
  x_diameter?: string
}
