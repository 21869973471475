import { RootReducerState } from '@redux/reducers'

const loginPendingSelector = (state: RootReducerState) => state.user.isLoading || false

const loginStatusSelector = (state: RootReducerState) => state.user.userLoggedIn || false

const userIsLoggingInSelector = (state: RootReducerState) => state.user.userIsLoggingIn || false

const loginErrorMsgSelector = (state: RootReducerState) => state.user.userLoginErrorMsg

const guestStatusSelector = (state: RootReducerState) => state.user.isGuest || false

const canGuestUserCheckoutSelector = (state: RootReducerState) => state.user.canGuestUserCheckout || false

const wcTokenSelector = (state: RootReducerState) => state.user.WCToken

const wcTrustedTokenSelector = (state: RootReducerState) => state.user.WCTrustedToken

const logonIdSelector = (state: RootReducerState) => state.user.details?.logonId || ''

const userIdSelector = (state: RootReducerState) => state.user.userId

const forUserIdSelector = (state: RootReducerState) => state.user.forUserId

const userPHIConsentSelector = (state: RootReducerState) => state.user.details?.x_optIn?.x_phiconsent

const userNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || ''
  const lastName = state.user.details?.lastName || ''
  return { firstName, lastName }
}

const userShortNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || ''
  const lastName = state.user.details?.lastName || ''

  if (!firstName && !lastName) {
    return null
  }

  let shortName = ''

  if (firstName) {
    shortName += firstName.charAt(0).toUpperCase() + firstName.slice(1)
  }

  if (lastName) {
    shortName += ` ${lastName.charAt(0).toLocaleUpperCase()}.`
  }

  return shortName.trim()
}

const userInitStatusSelector = (state: RootReducerState) => state.user.initiatedFromStorage

const userLastUpdatedSelector = (state: RootReducerState) => state.user.lastUpdated

const registrationStatusSelector = (state: RootReducerState) => state.user.userRegistration || false

const pendingRegistrationStatusSelector = (state: RootReducerState) => state.user.pendingRegistration || false

const firstAvailableEmailContactSelector = (state: RootReducerState) => {
  const contacts = state.user?.details?.contact || []
  const firstContactWithValidEmail = contacts.find(c => c.email1)
  return firstContactWithValidEmail?.email1
}

const userEmailSelector = (state: RootReducerState) => state.user.details?.email1

const userDetailsSelector = (state: RootReducerState) => state.user.details

const userTypeSelector = (state: RootReducerState) => state.user.userType

const areUserDetailsLoadingSelector = (state: RootReducerState) => state.user.areDetailsLoading

const loginLoadingStatusSelector = (state: RootReducerState) => state.user.isLoading || false

const firstAvailableContactInformationSelector = (state: RootReducerState) => state.user.details?.contact?.[0]

const customerSegmentSelector = (state: RootReducerState) => state.user.details?.x_data?.customerSegments?.[0]?.name

const userWalletSelector = (state: RootReducerState) => state.user.wallet

const userSelectedWalletSelector = (state: RootReducerState) => state.user.wallet?.selectedWallet || null

const returningCustomerButtonClickedSelector = (state: RootReducerState) => state.user.isReturningCustomerButtonClicked

const isAutoLoginSelector = (state: RootReducerState) => state.user.isAutologin

const isReorderIn3ClicksButtonClickedSelector = (state: RootReducerState) => state.user.isReorderIn3ClicksButtonClicked

const listOfSubscriptionsSelector = (state: RootReducerState) => state.user.details?.x_data?.subscriptions || []

const activeSubscriptionsQuantitySelector = (state: RootReducerState) => state.user?.activeSubscriptionsQuantity ?? 0

export {
  areUserDetailsLoadingSelector,
  customerSegmentSelector,
  firstAvailableContactInformationSelector,
  firstAvailableEmailContactSelector,
  forUserIdSelector,
  guestStatusSelector,
  canGuestUserCheckoutSelector,
  loginErrorMsgSelector,
  loginLoadingStatusSelector,
  loginStatusSelector,
  loginPendingSelector,
  logonIdSelector,
  pendingRegistrationStatusSelector,
  registrationStatusSelector,
  userDetailsSelector,
  userTypeSelector,
  userIsLoggingInSelector,
  userEmailSelector,
  userIdSelector,
  userInitStatusSelector,
  userLastUpdatedSelector,
  userNameSelector,
  userShortNameSelector,
  userSelectedWalletSelector,
  userWalletSelector,
  userPHIConsentSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
  returningCustomerButtonClickedSelector,
  isAutoLoginSelector,
  isReorderIn3ClicksButtonClickedSelector,
  listOfSubscriptionsSelector,
  activeSubscriptionsQuantitySelector,
}
