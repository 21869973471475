export const PERSONALIZATION_ID = 'personalizationID'
export const INITIATED_FROM_STORAGE = 'initiatedFromStorage'
export const IS_RETURNING_CUSTOMER_BUTTON_CLICKED = 'isReturningCustomerButtonClicked'
export const USER_IS_LOGGING_IN = 'userIsLoggingIn'
export const IS_REORDER_IN_3_CLICKS_BUTTON_CLICKED = 'isReorderIn3ClicksButtonClicked'
export const CAN_GUEST_USER_CHECKOUT = 'canGuestUserCheckout'

// For local storage temporal solution
export const USER_LOGGEDIN_STATUS = 'userLoggedIn'

export const USER_SEGMENT_COOKIE_NAME = 'userg'
